import { MaterialIcons } from '@expo/vector-icons';
import { AddIcon, Box, Flex, Icon, Image as NBImage, Spinner, View } from 'native-base';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';
import Cropper from 'react-easy-crop';
import { Modal } from 'react-native';

import {
  BoxWrapper,
  Button,
  TextFieldHookFrom,
  Typography,
} from '../../../../components';
import { API_HOST, IS_DESKTOP_SCREEN, theme } from '../../../../constants';
import { useNotificationsContext } from '../../../../context';
import {
  useAddFormattedPhoto,
  useIntl,
  usePhotoMutation,
  useUpdateAlloProductPictures,
  useUpdateEpicenterProductPictures,
  useUpdateRozetkaProductPictures,
} from '../../../../hooks';
import { LocalStorage } from '../../../../services';
import { colors } from '../../../../theme';
import { styles } from './styles';

const UploadImageWthDNDContext = ({
  photoToEdit,
  loadingProduct,
  productId,
  onClose,
  order,
  photos,
}) => {
  const intl = useIntl();
  const { showNotification } = useNotificationsContext();
  const [alt, setAlt] = useState(photoToEdit?.alt ?? '');
  const photo = useRef({ id: photoToEdit?.id || '', url: '' });
  const pathCropPhoto = photoToEdit?.formats?.find((el) => el.format === 'smallCrop')
    .pathWithTime
    ? `${API_HOST}/${
        photoToEdit?.formats?.find((el) => el.format === 'smallCrop').pathWithTime
      }`
    : '';
  const pathPhoto = photoToEdit?.path ? `${API_HOST}/${photoToEdit?.path}` : '';
  const [imageSrc, setImageSrc] = useState(pathPhoto);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState(pathCropPhoto);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState(false);

  const [photoMutation, { loading: photoMutationLoading }] = usePhotoMutation({
    productId,
  });

  const [updateRozetkaProductPictures] = useUpdateRozetkaProductPictures();
  const [updateAlloProductPictures] = useUpdateAlloProductPictures();
  const [updateEpicenterProductPictures] = useUpdateEpicenterProductPictures();

  const [addFormatedPhoto] = useAddFormattedPhoto(photo);

  const addImage = async (file, imgURL) => {
    setLoading(true);
    const token = await LocalStorage.getValue('token');
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name.split('.')[0]);
    formData.append('alt', alt);
    formData.append('productId', productId);
    formData.append('order', order);
    try {
      const response = await fetch(process.env.REACT_APP_UPLOAD_URL, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      photo.current.id = responseData.id;
      setImageSrc(imgURL);
      const updatedPhotos = [...photos, { order, path: responseData.path }];
      updateRozetkaProductPictures({
        variables: {
          internalId: Number(productId),
          pictures: updatedPhotos?.map((photo) => ({
            order: photo.order,
            url: photo.path,
          })),
        },
      });
      updateAlloProductPictures({
        variables: {
          internalId: Number(productId),
          pictures: updatedPhotos?.map((photo) => ({
            order: photo.order,
            url: photo.path,
          })),
        },
      });
      updateEpicenterProductPictures({
        variables: {
          internalId: Number(productId),
          pictures: updatedPhotos?.map((photo) => ({
            order: photo.order,
            url: photo.path,
          })),
        },
      });
      showNotification({ message: 'photo.uploaded' });
    } catch (error) {
      showNotification({
        title: 'app.error',
        message: error.message,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const onSave = () => {
    photoMutation({
      variables: {
        input: { id: photo.current.id, alt, productId: Number(productId) },
      },
      onCompleted: () => {
        showNotification({ message: 'photo.saved' });
        onClose();
      },
    });
  };

  const onDelete = () => {
    photoMutation({
      variables: { input: { id: photo.current.id, productId: null } },
      onCompleted: () => {
        showNotification({ message: 'photo.deleted' });
        const updatedPhotos = photos?.filter((photo) => photo?.id !== photo?.current?.id);
        updateRozetkaProductPictures({
          variables: {
            internalId: Number(productId),
            pictures: updatedPhotos?.map((photo) => ({
              order: photo.order,
              url: photo.path,
            })),
          },
        });
        updateAlloProductPictures({
          variables: {
            internalId: Number(productId),
            pictures: updatedPhotos?.map((photo) => ({
              order: photo.order,
              url: photo.path,
            })),
          },
        });
        updateEpicenterProductPictures({
          variables: {
            internalId: Number(productId),
            pictures: updatedPhotos?.map((photo) => ({
              order: photo.order,
              url: photo.path,
            })),
          },
        });
        onClose();
      },
    });
  };

  const onDrop = (item) => {
    if (item) {
      const file = item.files[0];
      const imgURL = URL.createObjectURL(file);
      addImage(file, imgURL);
    }
  };

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (onDrop) {
          onDrop(item);
        }
      },
      //eslint-disable-next-line
      canDrop(item) {
        return true;
      },

      collect: (monitor) => {
        //eslint-disable-next-line
        const item = monitor.getItem();
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [onDrop],
  );
  //eslint-disable-next-line
  const isActive = canDrop && isOver;

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      setCroppedImage(croppedImage);

      addFormatedPhoto({
        variables: {
          input: {
            photoId: photo.current.id,
            format: 'smallCrop',
            top: croppedAreaPixels.y,
            left: croppedAreaPixels.x,
            width: croppedAreaPixels.width,
            height: croppedAreaPixels.height,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
    //eslint-disable-next-line
  }, [imageSrc, croppedAreaPixels]);

  return (
    <BoxWrapper style={styles.modalWrapper} rounded='5px' mb={0} w={448} pb={6}>
      {!loadingProduct ? (
        <Box>
          <Typography mb={1} intlId='app.productPhoto' />
          <Typography
            style={styles.textInfoUploadFile}
            intlId='app.infoUploadFile'
            mb={6}
          />

          {!imageSrc && !loading && (
            <View ref={drop}>
              <label htmlFor='file'>
                <Button
                  htmlFor='file'
                  bgColor={theme.colors.bgLightGrayOpacity}
                  borderWidth={1}
                  borderColor={theme.colors.bgLightGray}
                  borderStyle='solid'
                  borderRadius='3px'
                  h={400}
                  w={400}
                  _hover={{ bgColor: theme.colors.bgLightGray }}
                >
                  <AddIcon size={'20px'} color={theme.colors.primary} />
                </Button>
              </label>

              <Flex justify={'flex-end'}>
                <input
                  // eslint-disable-next-line
                  style={{ display: 'none' }}
                  type='file'
                  accept='image/*'
                  name={`file`}
                  id={`file`}
                  onChange={async (e) => {
                    let file = await e.target.files[0];
                    let imgURL = URL.createObjectURL(file);
                    addImage(file, imgURL);
                  }}
                />
              </Flex>
            </View>
          )}
          {loading && (
            <View>
              <Flex style={styles.download} paddingY='lg'>
                <Spinner size='lg' />
                <Typography
                  style={styles.text}
                  intlId='app.uploadingPhoto'
                  variant='heading'
                />
              </Flex>
            </View>
          )}
          {imageSrc && !croppedImage && !loading && (
            <>
              <BoxWrapper width={'400px'} height={'400px'}>
                <Cropper
                  image={!loading && imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </BoxWrapper>
              <Box width={400}>
                <input
                  type='range'
                  min={1}
                  max={3}
                  step={0.1}
                  value={zoom}
                  onInput={(e) => {
                    onZoomChange(e.target.value);
                  }}
                ></input>
              </Box>
            </>
          )}
          {imageSrc && croppedImage && (
            <View ref={drop}>
              <Box>
                <NBImage
                  width={400}
                  height={400}
                  source={croppedImage}
                  resizeMode='contain'
                  alt={intl.formatMessage({ id: 'app.productPhoto' })}
                />
                <Icon
                  position={'absolute'}
                  left={190}
                  top={190}
                  name='edit'
                  as={MaterialIcons}
                  size={26}
                  color={colors.primary.default}
                  onPress={() => setCroppedImage(null)}
                />
              </Box>
            </View>
          )}
        </Box>
      ) : (
        <View>
          <Flex style={styles.download} paddingY='lg'>
            <Spinner size='lg' />
          </Flex>
        </View>
      )}
      <TextFieldHookFrom
        mt={6}
        width={'100%'}
        name='alt'
        label='app.alt'
        placeholderIntlId='app.altPlaceholder'
        value={alt}
        onChange={(val) => setAlt(val)}
      />
      <Flex justify='flex-end' mt={6}>
        {imageSrc && croppedImage && (
          <Button
            w={150}
            size='sm'
            intlId='app.remove'
            mr={6}
            isDisabled={loading || photoMutationLoading}
            backgroundColor={colors.danger}
            onPress={() => {
              onDelete();
            }}
          />
        )}
        <Button
          intlId='app.save'
          w={136}
          size='sm'
          isDisabled={loading || !imageSrc || photoMutationLoading}
          onPress={() => {
            if (imageSrc && !croppedImage) {
              showCroppedImage();
            } else {
              onSave();
            }
          }}
        />
      </Flex>
      <Flex justify='flex-end' mt={6}>
        <Button intlId='app.close' onPress={onClose} variant='ghost' />
      </Flex>
    </BoxWrapper>
  );
};

export const UploadImageModal = ({
  isOpen,
  onClose,
  photoToEdit,
  productId,
  order,
  loading = false,
  photos,
}) => {
  return (
    <Modal
      animationType='fade'
      transparent={IS_DESKTOP_SCREEN}
      visible={isOpen}
      onRequestClose={onClose}
    >
      <View style={styles.modalOverlay}>
        <DndProvider backend={HTML5Backend}>
          <UploadImageWthDNDContext
            photoToEdit={photoToEdit}
            loadingProduct={loading}
            productId={productId}
            onClose={onClose}
            order={order}
            photos={photos}
          />
        </DndProvider>
      </View>
    </Modal>
  );
};

UploadImageWthDNDContext.propTypes = {
  onClose: PropTypes.func.isRequired,
  photoToEdit: PropTypes.object,
  loadingProduct: PropTypes.bool,
  productId: PropTypes.string,
  order: PropTypes.number,
  photos: PropTypes.array,
};

UploadImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangeCode: PropTypes.func.isRequired,
  closeAfterAdding: PropTypes.bool,
  loading: PropTypes.bool,
  photoToEdit: PropTypes.object,
  productId: PropTypes.string,
  order: PropTypes.number,
  photos: PropTypes.array,
};

const createImage = (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
};

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 *
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
async function getCroppedImg(imageSrc, pixelCrop = 0, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const safeArea = Math.max(image.width, image.height) * 2;

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
  );

  // As Base64 string
  return canvas.toDataURL('image/jpeg');
}
