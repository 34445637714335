import React, { useEffect, useState } from 'react';

import {
  DesktopTable,
  Layout,
  MobileTable,
  RequestHandler,
} from '../../../../../components';
import { IS_DESKTOP_SCREEN } from '../../../../../constants';
import {
  useGetStatusesQuery,
  useGetEpicenterOrderStatusMapsQuery,
  useHistory,
} from '../../../../../hooks';
import { getOrderStatusName } from '../../../../../utils/getOrderStatusName';
import EpicenterOrderStatusMobileItem from './EpicenterOrderStatusMobileItem';

const COLUMNS = [
  { intlId: 'app.name' },
  { intlId: 'orderStatuses.description' },
  { intlId: 'app.status' },
];

const EpicenterOrderStatuses = () => {
  const navigation = useHistory();
  const [statuses, setStatuses] = useState([]);

  const { data: statusesData } = useGetStatusesQuery();

  const { data: epicenterOrderStatusMapsData, ...epicenterOrderStatusMapsQueryProps } =
    useGetEpicenterOrderStatusMapsQuery();

  useEffect(() => {
    if (!statusesData) return;
    setStatuses(statusesData?.statuses);
  }, [statusesData]);

  const handleEditButtonClick = (epicenterStatusName, statusId) => {
    navigation.push(
      '/settings/ordersstatuses/epicenter/' + epicenterStatusName + '/' + statusId,
    );
  };

  const handleClickAddButton = () => {
    navigation.push('/settings/ordersstatuses/epicenter/add');
  };

  if (IS_DESKTOP_SCREEN) {
    return (
      <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
        <RequestHandler {...epicenterOrderStatusMapsQueryProps}>
          <DesktopTable>
            <DesktopTable.Head>
              {COLUMNS?.map(({ intlId, sortId }) => (
                <DesktopTable.Title key={intlId} intlId={intlId} sortId={sortId} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {epicenterOrderStatusMapsData?.epicenterOrderStatusMaps?.map((item) => (
                <DesktopTable.Row
                  key={item?.id}
                  onEditButtonClick={() =>
                    handleEditButtonClick(item.epicenterStatusName, item.statusId)
                  }
                >
                  <DesktopTable.Cell>{item.epicenterStatusName}</DesktopTable.Cell>
                  <DesktopTable.Cell>{item.epicenterStatusDesc}</DesktopTable.Cell>
                  <DesktopTable.Cell>
                    {getOrderStatusName(statuses, item.statusId)}
                  </DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        </RequestHandler>
      </Layout>
    );
  }
  return (
    <Layout isScrollable={false} onActionButtonPress={handleClickAddButton}>
      <RequestHandler {...epicenterOrderStatusMapsQueryProps}>
        <MobileTable
          data={epicenterOrderStatusMapsData?.epicenterOrderStatusMaps}
          renderItem={(item) => (
            <EpicenterOrderStatusMobileItem
              onItemPress={() =>
                handleEditButtonClick(item.epicenterStatusName, item.statusId)
              }
              name={item.epicenterStatusName}
              description={item.epicenterStatusDesc}
              status={getOrderStatusName(statuses, item.statusId)}
            />
          )}
        />
      </RequestHandler>
    </Layout>
  );
};

export default EpicenterOrderStatuses;
